<template lang="pug">
main.homePage 
  HomeIntro
  HomeBanner
  HomeAbout
  Slider
  Catalog
  Contact
  DrawLine._x._fullWidth._1
  DrawLine._y._2
  DrawLine._y._3
  DrawLine._x._fullWidth._4
  DrawLine._x._fullWidth._5
  .whiteLayer
</template>

<script>
  import DrawLine from '@/components/DrawLine.vue';

  export default {
    components: {
      HomeIntro: () => import('./HomeIntro.vue'),
      HomeBanner: () => import('./HomeBanner.vue'),
      HomeAbout: () => import('./HomeAbout.vue'),
      Slider: () => import('@/components/Slider.vue'),
      Catalog: () => import('@/components/Catalog.vue'),
      Contact: () => import('@/components/Contact.vue'),
      DrawLine,
    },
    async mounted() {
      // Draws
      // NOT DRY on SinglePage
      const desktop = window.matchMedia('(min-width: 769px)').matches;
      const windowHeight = document.documentElement.clientHeight;
      const halfWindowHeight = document.documentElement.clientHeight / 2;
      const verticalTrigger = desktop ? halfWindowHeight : halfWindowHeight + windowHeight / 3;
      function documentScrolled() {
        YDrawElements.forEach((el, i) => {
          if (el.getBoundingClientRect().top < verticalTrigger) {
            el.classList.add('_active');
            if (i == YDrawElements.length - 1) {
              window.removeEventListener('scroll', documentScrolled);
              console.log('documentScrolled clear');
            }
          }
        });
      }
      const YDrawElements = [...document.querySelectorAll('.drawLine')];
      window.addEventListener('scroll', documentScrolled);
    },
  };
</script>

<style lang="scss">
  .homePage {
    .drawLine {
      &._1 {
        grid-row: 4;
      }
      &._2 {
        grid-row: 3 / 5;
        grid-column: 9;
        @include tablet {
          grid-row: 4 / 5;
        }
        @include phone {
          display: none;
        }
      }
      &._3 {
        grid-row: 6;
        grid-column: 9;
        @include tablet {
          grid-row: 8;
        }
        @include phone {
          display: none;
        }
      }
      &._4 {
        grid-row: 7;
        @include tablet {
          grid-row: 9;
        }
      }
      &._5 {
        grid-row: 7;
        align-self: flex-end;
        @include tablet {
          grid-row: 9;
        }
      }    
    }
  } //selector for set layout
</style>
